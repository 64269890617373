import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/home',
  //   pathMatch: 'full'
  // },
  // {
  //   path: "",
  //   loadChildren: () => import("./airasia/airasia.module").then(m => m.AirasiaModule)
  // },
  {
    path: "",
    loadChildren: () =>
      import("./vistara/vistara.module").then(m => m.VistaraModule)
  }
  // {   path: 'home',   component: HomeComponent   },
  // { path: 'booking-confirmation', component: BookingConfirmationComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
