import {
  throwError as observableThrowError,
  Observable,
  BehaviorSubject
} from "rxjs";
import { take, filter, catchError, switchMap, finalize } from "rxjs/operators";
import { Injectable, Injector } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpErrorResponse
} from "@angular/common/http";
import { MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class ErrorInterceptService implements HttpInterceptor {
  count: number;

  constructor(
    private _snackbar: MatSnackBar,
    private _router: Router
  ) {
    this.count = 0;
  }

  // intercept request and add token
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
  > {
    return next.handle(req).pipe(
      catchError(err => {
        console.log("error in interceptor", err);
        if (err instanceof HttpErrorResponse) {
          if (
            err.error.error == "invalid_token" ||
            err.status == 401 ||
            err.status == 0
          ) {
            this._snackbar.open(
              "Your session expired please login thank you!",
              "X",
              { duration: 3000 }
            );
            localStorage.clear();
            sessionStorage.clear();
            this._router.navigate(["/home"]);
            // this.count++;
          } else if (err.status == 0) {
            this._snackbar.open(
              "Please Check Internet Connection Thank You !",
              "X",
              { duration: 3000 }
            );
          }
          return observableThrowError(err);
        }
      })
    );
  }
}
